
import { defineComponent, PropType } from "vue";
import SvgIcon from "@/components/ui/SvgIcon.vue";
export default defineComponent({
  name: "EmptyBlock",
  components: { SvgIcon },
  props: {
    icon: { type: String, required: false },
    description: { type: String, required: true },
    fullscreen: { type: Boolean, default: false },
    iconColor: {
      type: String as PropType<"grey" | "dark">,
      default: "grey"
    }
  }
});
