import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppExpendedTableBody = _resolveComponent("AppExpendedTableBody")!
  const _component_AppTableLayout = _resolveComponent("AppTableLayout")!

  return (_openBlock(), _createBlock(_component_AppTableLayout, _mergeProps({
    "table-head": _ctx.tableHead,
    "pagination-capture-key": _ctx.paginationCaptureKey,
    "total-rows": _ctx.totalRows,
    "per-page": _ctx.perPage,
    "current-page": _ctx.currentPage,
    class: "accordion-table inline-table"
  }, _ctx.$attrs.class, {
    onSort: _cache[0] || (_cache[0] = 
      (...arg) => {
        _ctx.$emit('sort', ...arg);
      }
    ),
    onChangeViewConfiguration: _cache[1] || (_cache[1] = 
      conf => {
        _ctx.$emit('changeViewConfiguration', conf);
      }
    )
  }), {
    "table-body": _withCtx(() => [
      _createVNode(_component_AppExpendedTableBody, {
        "table-body": _ctx.updatedTableBodyRows,
        onAccordionOpen: _ctx.onAccordionOpen
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["table-body", "onAccordionOpen"])
    ]),
    _: 3
  }, 16, ["table-head", "pagination-capture-key", "total-rows", "per-page", "current-page"]))
}