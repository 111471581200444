<template>
  <div
    :class="{
      'app-accordion': true,
      'app-accordion-opened': opened,
      'w-100': true
    }"
  >
    <div class="app-accordion--heading" @click="opened = !opened">
      <div class="d-flex align-items-center">
        <div class="heading-content">
          <slot name="heading" />
        </div>
        <div class="accordion-icon"><svg-icon icon="chevron" /></div>
      </div>
    </div>

    <div class="app-accordion--body">
      <CollapsableCore :collapsed-height="0" :opened="opened">
        <transition name="fade">
          <div v-if="opened" class="body-content">
            <slot name="body" />
          </div>
        </transition>
      </CollapsableCore>
    </div>
  </div>
</template>

<script>
import CollapsableCore from "@/components/ui/Collapsable/CollapsableCore";
import SvgIcon from "@/components/ui/SvgIcon";
export default {
  name: "Accordion",
  components: { SvgIcon, CollapsableCore },
  inject: ["onAccordionOpen", "onAccordionClose"],
  props: {
    closedHeight: { type: Number, default: 70 },
    identifier: { type: Number, default: -1 }
  },
  data() {
    return {
      opened: false,
      removed: true
    };
  },
  watch: {
    opened(status) {
      const { onAccordionOpen, onAccordionClose } = this;

      if (status) {
        this.removed = false;
        onAccordionOpen && onAccordionOpen(this.identifier);
      } else {
        setTimeout(() => {
          this.removed = true;
          onAccordionClose && onAccordionClose();
        }, 100);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$iconWidth: $accordion-icon-width;
.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 0.8s;
}
.fade-slow-enter, .fade-slow-leave-to /* .fade-slow-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.accordion-icon {
  width: $iconWidth;
  .icon {
    transition: transform 0.3s;
    font-size: 0.7em;
    transform: rotate(-90deg);
  }
}

.app-accordion {
  &--heading {
    cursor: pointer;
    .heading-content {
      width: calc(100% - #{$iconWidth});
    }
  }
  &--heading,
  &--body {
    position: relative;
    z-index: 9;
  }

  &-opened {
    .accordion-icon {
      .icon {
        transform: rotate(0);
      }
    }
  }
}
</style>
