<template>
  <div
    class="fullscreen-loader"
    :style="{ left: `${x}px`, width: `calc(100% - ${x}px)` }"
  >
    <AppPreloader />
  </div>
</template>

<script>
import AppPreloader from "@/components/ui/AppPreloader";

export default {
  name: "FullscreenLoader",
  components: { AppPreloader },
  props: { x: { type: Number, default: 0 } }
};
</script>

<style lang="scss">
.fullscreen-loader {
  background: var(--bs-body-bg);
  height: 100%;

  z-index: 9999999999;
  color: $color-primary-blue;
  position: absolute;
  top: 0;

  font-size: 20px;

  .spinner {
    position: sticky;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
