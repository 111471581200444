import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28469b4f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-block-description" }
const _hoisted_2 = {
  key: 1,
  class: "empty-block--button-wrapper mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["empty-block d-flex justify-content-center flex-column align-items-center", {
      [`color-${_ctx.iconColor}`]: true,
      fullscreen: _ctx.fullscreen
    }])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_svg_icon, {
          key: 0,
          icon: _ctx.icon,
          class: "mb-3"
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.description), 1),
    (_ctx.$slots.button)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "button", {}, undefined, true)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}