<template>
  <div class="shadow-control">
    <FormControlWithIcon
      v-if="icon"
      v-bind="$attrs"
      :id="id"
      :icon="icon"
      @change="changeHandler"
    />

    <FormControl
      v-else
      v-bind="$attrs"
      :id="id"
      name=""
      @change="changeHandler"
    />
  </div>
</template>

<script>
import FormControlWithIcon from "@/components/Form/FormControlWithIcon";
import FormControl from "@/components/Form/FormControl";
let changeTimout = null;
export default {
  name: "ShadowControl",
  components: { FormControl, FormControlWithIcon },
  props: {
    icon: String,
    name: { type: String, required: true },
    id: { type: String, required: true },
    inputTimeout: { type: Boolean, default: false }
  },
  emits: { change: null },
  methods: {
    changeHandler(evt) {
      if (this.inputTimeout) {
        clearTimeout(changeTimout);
        changeTimout = setTimeout(() => {
          this.$emit("change", evt);
        }, 300);
      } else {
        this.$emit("change", evt);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.shadow-control {
  @include shadowBlock();
}
</style>
