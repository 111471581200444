<template>
  <div class="partner d-flex align-items-center">
    <div v-if="image" class="partner-logo me-3">
      <img :src="image" :alt="name" />
    </div>

    <div class="partner-info">
      <strong class="xl">{{ title }}</strong>
      <span v-if="description" class="secondary-text">{{ description }}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Partner",
  props: {
    title: { type: String, required: true },
    description: { type: String, required: false },
    image: { type: String, default: "" }
  }
};
</script>

<style scoped lang="scss">
.partner-logo {
  font-size: 24px;
  width: 1em;
  height: 1em;

  border-radius: 50%;

  position: relative;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
