
import SelectInput from "@/components/Form/SelectInput.vue";
import { computed, defineComponent, toRefs } from "vue";
import { IBundle } from "@/api/services/packages/esim/location-packages/types";
import { useStore } from "vuex";
import {
  ISelectOption,
  useLocationPackagesSelect
} from "@/hooks/esim/location-packages/modules/useLocationPackagesSelect";
import { watch } from "fs";

export default defineComponent({
  name: "SelectBundle",
  components: { SelectInput },

  props: {
    selectedBundleId: { type: Number, default: -1 },
    filterByZone: { type: String, default: "" },
    provider: {
      type: String,
      default: ""
    }
  },
  emits: { select: null },

  setup(props, { emit }) {
    const store = useStore();
    store.dispatch("fetchProviders");

    const { selectedBundleId, filterByZone } = toRefs(props);

    const isReseller = computed(() => {
      return props.provider === "Reseller";
    });

    const bundles = computed(() => {
      const providers = store.getters.providers;
      const providerInfo = providers.find(
        (p: any) => p.name === props.provider
      );
      if (!props.provider || !providerInfo) {
        return [];
      }

      const bundles = isReseller.value
        ? providerInfo.resellerBundles
        : providerInfo.multitelBundles;

      if (!filterByZone.value) {
        return bundles;
      }

      if (filterByZone.value === "-1") {
        return [];
      }

      const updatedFilterByZone = filterByZone.value
        .replaceAll(" ", "")
        .toLowerCase();

      if (isReseller.value) {
        return bundles;
      }
      return bundles.filter(({ name }: IBundle) => {
        return name
          .replaceAll(" ", "")
          .toLowerCase()
          .includes(updatedFilterByZone);
      });
    });

    const { optionsList, selectOption } = useLocationPackagesSelect({
      parentSelectedOptionId: selectedBundleId,
      initialOptions: bundles,
      selectByKeyName: "id",
      onOptionChanged(option: ISelectOption) {
        emit("select", option.id);
      }
    });

    return { optionsList, selectOption };
  }
});
