<template>
  <div class="card d-flex flex-column justify-content-between">
    <div>
      <div v-if="title" class="card-head d-flex justify-content-between">
        <h4 class="card-title subtitle">{{ title }}</h4>
        <slot name="title" />
      </div>
      <div v-if="$slots['tabs-title']" class="card-tabs--title">
        <slot name="tabs-title" />
      </div>

      <div v-if="$slots.tabs" class="card-tabs">
        <slot name="tabs"></slot>
      </div>
      <div v-if="$slots['tabs-content']" class="card-tabs-content">
        <slot name="tabs-content"></slot>
      </div>

      <div v-if="$slots.default" class="card-body">
        <slot />
      </div>
    </div>

    <div v-if="$slots.footer" class="card-footer mt-3">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: { title: { type: [String, null], required: false, default: null } }
};
</script>

<style lang="scss">
.card .simplebar-track.simplebar-vertical {
  right: 10px !important;
}

.card {
  border-radius: $border-radius;

  background-color: #fff;
  width: 100%;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  .card-head + &-body {
    margin-top: 30px;
  }

  //$card-padding-y
  .card-head,
  .card-body,
  .card-tabs--title {
    padding-top: $card-padding-y;

    padding-left: $card-padding-x;
    padding-right: $card-padding-x;
  }

  .card-footer {
    padding-left: $card-padding-x;
    padding-right: $card-padding-x;
    padding-bottom: $card-padding-y;
  }
  .card-head {
    & + .card-body {
      padding-top: 0;
    }
  }

  .card-tabs {
    padding-top: $card-padding-y;
    .tabs {
      padding-left: $card-padding-x;
      padding-right: $card-padding-x;
    }
  }
  .card-padding-x {
    padding-left: $card-padding-x;
    padding-right: $card-padding-x;
  }
  .card-tabs-content {
  }

  // SELECT DROPDOWN
  .select-dropdown {
    position: relative;
    left: 50%;
    width: calc(100% + 2em);
    transform: translateX(-50%);
    transition: height 0.2s;
    height: 3.8rem;
    max-width: 115%;
    margin-top: -1.5rem;

    .dropdown {
      max-width: 100%;
    }
    .dropdown-heading {
      height: 4rem;
      transition: height 0.25s;
    }
    &:not(.select-shown) {
      //height: 3.2rem;
      .dropdown-heading {
        height: 3.2rem;
      }
    }
    .select-option {
      padding-left: 1.05rem;
      font-size: 13px;
      padding-right: 0.2rem;
      b {
        font-size: 1.15em;
      }
      .app-radio {
        font-size: 1.38rem;
      }
      strong {
        font-size: inherit !important;
        white-space: pre-wrap;
      }
    }

    .selected-option {
      b.single {
        &:after {
          content: " - ";
        }
      }
    }
    .dropdown.shown {
      .dropdown-heading {
        border-bottom: 1px solid $border-color;
      }
    }

    .select-list {
      .select-option {
        padding-left: 1.15rem;
        padding-right: 1.4em;
        align-items: center;
        border-bottom: 1px solid $border-color;
        b {
          display: block;
        }
      }

      & > *:last-child {
        .select-option {
          border-bottom: none;
        }
      }
    }
  }
}
</style>
