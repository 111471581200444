import SimpleBar from "simplebar";

export const useCustomScrollbar = {
  data() {
    return {
      scrollArea: null,
      scrollbar: null
    };
  },

  methods: {
    scrollbarInit() {
      const conf = this.scrollbarConfig ?? {};
      this.scrollbar = new SimpleBar(this.scrollArea, {
        autoHide: false,
        ...conf
      });
    },

    scrollbarDestroy() {
      this.scrollbar?.unMount();
      this.scrollbar = null;
    }
  },
  watch: {
    scrollArea(node) {
      if (node) {
        this.scrollbarInit();
      } else {
        this.scrollbarDestroy();
      }
    }
  },

  mounted() {
    setTimeout(() => {
      this.scrollArea = this.$refs.scrollArea;
    }, 150);
  },
  unmounted() {
    this.scrollArea = null;
  }
};
