<template>
  <div
    ref="collapsable"
    class="collapsable-hidden"
    :class="{ init, overflow: collapsedHeight > 0 }"
  >
    <template v-if="unmountOnClose">
      <transition
        :css="false"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <div v-if="shown">
          <div ref="el" class="content-inner">
            <slot />
          </div>
        </div>
      </transition>
    </template>

    <template v-else>
      <transition
        :css="false"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <div v-show="shown">
          <div ref="el" class="content-inner">
            <slot />
          </div>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import gsap from "gsap";
import * as ResizeSensor from "resize-sensor";

export default {
  name: "CollapsableCore",
  props: {
    collapsedHeight: { type: Number, required: true },
    opened: { type: Boolean, required: true },
    heightAuto: { type: Boolean, default: false },
    unmountOnClose: { type: Boolean, default: true },
    duration: { type: Number, default: 0.6 }
  },

  emit: ["onOpen", "onClose", "done", "changeOverflowStatus"],

  data() {
    return {
      init: false,
      collapsed: !this.opened,
      resizeTimeout: null,
      contentHeight: 0
    };
  },

  computed: {
    shown() {
      return this.opened || this.collapsedHeight > 0;
    },

    $collapsable() {
      return this.$refs.collapsable;
    },

    isOverflow() {
      return this.contentHeight > this.collapsedHeight;
    }
  },

  watch: {
    isOverflow() {
      this.onOverflowChange();
    },
    opened: {
      immediate: true,
      handler() {
        if (this.collapsedHeight > 0) {
          this.collapsed = this.isOverflow ? !this.opened : false;
        } else {
          this.collapsed = !this.opened;
        }
      }
    },

    collapsed() {
      if (this.collapsedHeight > 0) {
        this.toggle();
      }
    }
  },

  mounted() {
    const resizableElement = this.$refs.el;

    this.updateHeight();
    this.onOverflowChange();

    if (resizableElement) {
      this.resizeSensor = new ResizeSensor(resizableElement, () => {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
          this.updateHeight();
        }, 20);
      });
    }
  },

  beforeUnmount() {
    if (this.resizeSensor) {
      ResizeSensor.detach(this.resizeSensor);
    }
  },

  methods: {
    onOverflowChange() {
      if (this.isOverflow) {
        this.collapsed = this.isOverflow ? !this.opened : false;
      } else {
        this.collapsed = false;
      }

      this.$emit("changeOverflowStatus", this.isOverflow);
    },

    updateHeight() {
      const { el } = this.$refs;
      if (el) {
        this.contentHeight = parseInt(window.getComputedStyle(el).height);
      }
    },

    beforeEnter(el) {
      gsap.set(el, { height: this.collapsedHeight, autoAlpha: 0 });
    },

    enter(el, done) {
      el.style.willChange = "height";
      setTimeout(() => {
        gsap.to(el, {
          height: this.collapsed ? this.collapsedHeight : "auto",
          autoAlpha: 1,
          duration: this.duration,
          ease: "expo.inOut",
          onComplete: () => {
            el.style.willChange = "none";
            done();
          }
        });
      }, 300);
    },

    leave(el, done) {
      el.style.willChange = "height";

      gsap.to(el, {
        autoAlpha: 0,
        height: this.collapsedHeight,
        duration: this.duration,
        ease: "expo.inOut",
        onComplete: () => {
          el.style.willChange = "none";
          done();
        }
      });
    },

    toggle(duration = this.duration) {
      if (this.$collapsable) {
        const { collapsed, collapsedHeight } = this;

        const conf = {
          height: collapsed ? collapsedHeight : "auto",
          onComplete: this.$emit("done", !collapsed)
        };

        if (duration === 0) {
          gsap.set(this.$collapsable, conf);
        } else {
          gsap.to(this.$collapsable, { ...conf, duration });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.collapsable {
  &-hidden {
    border-radius: $border-radius;

    &.overflow {
      overflow: hidden;
    }
  }
}
</style>
