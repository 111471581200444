import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createElementBlock as _createElementBlock, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppTabsHead = _resolveComponent("AppTabsHead")!
  const _component_AppTabsBody = _resolveComponent("AppTabsBody")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, _mergeProps(_ctx.$attrs, {
    key: 1,
    opened: _ctx.opened,
    onHide: _ctx.hide
  }), {
    "modal-tabs": _withCtx(() => [
      (_ctx.opened)
        ? (_openBlock(), _createBlock(_component_AppTabsHead, {
            key: 2,
            modelValue: _ctx.selectedTabIdx,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTabIdx) = $event)),
            tabs: _ctx.tabsHead
          }, null, 8, ["modelValue", "tabs"]))
        : _createCommentVNode("", true),
      (_ctx.opened)
        ? (_openBlock(), _createBlock(_component_AppTabsBody, {
            key: 3,
            "selected-tab-idx": _ctx.modelValue
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 8, ["selected-tab-idx"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      (_ctx.$slots['modal-button'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "modal-button")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["opened", "onHide"]))
}