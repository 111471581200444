import gsap from "gsap";
const DURATION = 1;

export interface IUseCollapseAnimation {
  beforeEnter: (el: Element) => void;
  enter: (el: Element, done: () => void) => void;
  leave: (el: Element, done: () => void) => void;
}

export const useCollapseAnimation: IUseCollapseAnimation = {
  beforeEnter(el: Element) {
    gsap.set(el, { autoAlpha: 0, height: 0 });
  },

  enter(el: Element, done: Function) {
    gsap.to(el, {
      height: "auto",
      autoAlpha: 1,
      duration: DURATION,
      ease: "expo.inOut",
      onComplete: () => {
        done();
      }
    });
  },

  leave(el: Element, done: Function) {
    gsap.to(el, {
      height: 0,
      autoAlpha: 0,
      duration: DURATION,
      ease: "expo.inOut",
      delay: DURATION * 0.2,
      onComplete: () => {
        done();
      }
    });
  }
};
