
import {
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  Ref,
  watch
} from "vue";
import { computed } from "@vue/reactivity";
import SortButton from "../ui/SortButton.vue";

export default defineComponent({
  name: "AppTabsHead",
  components: { SortButton },
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array as PropType<string[]>,
      required: true
    },
    margin: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const scrollItems: Ref<HTMLElement[]> = ref([]);
    const scrollController: Ref<HTMLElement | null> = ref(null);
    const scrollContent: Ref<HTMLElement | null> = ref(null);
    const scrollControllerWidth = ref(0);
    const scrollContentWidth = ref(0);

    const scrollCount = ref(0);
    const scrollTimeout = ref(setTimeout(() => {}));

    watch(
      () => scrollCount.value,
      count => {
        if (scrollController.value) {
          scrollController.value.scroll({
            top: 0,
            left: count,
            behavior: "smooth"
          });
        }
      },
      { immediate: true }
    );

    const scrollable = computed(
      () => scrollControllerWidth.value < scrollContentWidth.value
    );
    const showPrevButton = computed(() => {
      return scrollCount.value > 10;
    });

    const showNextButton = computed(() => {
      return (
        scrollable &&
        scrollCount.value <
          scrollContentWidth.value - scrollControllerWidth.value - 15
      );
    });

    function resizeHandler(link: Ref<number>) {
      return ([{ contentRect }]: ResizeObserverEntry[]) => {
        link.value = contentRect.width;
      };
    }

    const controllerResizeObserver = new ResizeObserver(
      resizeHandler(scrollControllerWidth)
    );

    const contentResizeObserver = new ResizeObserver(
      resizeHandler(scrollContentWidth)
    );

    onMounted(() => {
      nextTick(() => {
        if (scrollController.value) {
          controllerResizeObserver.observe(scrollController.value);
          scrollControllerWidth.value = scrollController.value.getBoundingClientRect().width;
          scrollController.value.addEventListener("scroll", onScrollHandler);
        }

        if (scrollContent.value) {
          contentResizeObserver.observe(scrollContent.value);
          scrollContentWidth.value = scrollContent.value.getBoundingClientRect().width;
        }
      });
    });

    onBeforeUnmount(() => {
      contentResizeObserver.disconnect();
      contentResizeObserver.disconnect();
      scrollController.value &&
        scrollController.value.removeEventListener("scroll", onScrollHandler);
    });

    function setScrollItem(item: HTMLElement) {
      if (!item || scrollItems.value.includes(item)) return;
      nextTick(() => {
        scrollItems.value.push(item);
      });
    }

    function onScrollHandler() {
      clearTimeout(scrollTimeout.value);

      scrollTimeout.value = setTimeout(() => {
        if (scrollController.value) {
          scrollCount.value = scrollController.value.scrollLeft;
        }
      }, 100);
    }

    function scrollToHandler(direction: "forward" | "backward") {
      if (direction === "forward") {
        scrollCount.value = scrollCount.value + 300;
        return;
      }

      scrollCount.value = scrollCount.value - 300;
    }

    return {
      setScrollItem,
      showPrevButton,
      showNextButton,
      scrollToHandler,
      scrollContentWidth,
      scrollControllerWidth,
      scrollController,
      scrollContent,
      scrollCount,
      selectTabHandler(idx: number) {
        emit("update:modelValue", idx);
      }
    };
  }
});
