import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "select-bundle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectInput = _resolveComponent("SelectInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectInput, {
      id: "select-bundle",
      options: _ctx.optionsList,
      "use-search": true,
      disabled: !_ctx.provider,
      "list-max-height": "200px",
      "list-max-width": "450px",
      label: "Bundle",
      "empty-key": "Bundles",
      onSelect: _ctx.selectOption
    }, null, 8, ["options", "disabled", "onSelect"])
  ]))
}