import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, Transition as _Transition, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container loader-container"
}
const _hoisted_2 = { style: {"overflow":"hidden"} }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullscreenLoader = _resolveComponent("FullscreenLoader")!
  const _component_BtnContent = _resolveComponent("BtnContent")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_EmptyBlock = _resolveComponent("EmptyBlock")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-table-controller", { 'min-height-1': _ctx.tableLoad }])
  }, [
    _createVNode(_Transition, {
      name: "fade",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.useLoader && (_ctx.tableLoading || _ctx.appLoading))
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_FullscreenLoader)
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_ctx.showTable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _renderSlot(_ctx.$slots, "default")
                  ]))
                : (_openBlock(), _createBlock(_component_EmptyBlock, {
                    key: 1,
                    icon: _ctx.emptyIcon,
                    description: _ctx.emptyText,
                    "icon-color": "dark",
                    fullscreen: true
                  }, _createSlots({ _: 2 }, [
                    (_ctx.emptyButtonText)
                      ? {
                          name: "button",
                          fn: _withCtx(() => [
                            _createVNode(_component_AppButton, {
                              type: "secondary",
                              size: "lg",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('emptyButtonClick')))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_BtnContent, {
                                  icon: "plus",
                                  label: _ctx.emptyButtonText
                                }, null, 8, ["label"])
                              ]),
                              _: 1
                            })
                          ]),
                          key: "0"
                        }
                      : undefined
                  ]), 1032, ["icon", "description"]))
            ]))
      ]),
      _: 3
    })
  ], 2))
}