import { IRate } from "@/api/interfaces/rates/common";
import { ILocationPackageListItemEditDto } from "@/hooks/esim/location-packages/types/useLocationPackageListType";
import {
  IRegionRates,
  ISubregionRates,
  IZoneRates
} from "@/store/locationPackages";

const priceCeil = (price: number): number => {
  const fixedPrice = Number(price.toFixed(2).split(".")[1]);
  if (fixedPrice <= 50 && fixedPrice !== 0) {
    return Math.ceil(price) - 0.5;
  }
  return Math.ceil(price);
};

export const getNewPrice = (
  $package: ILocationPackageListItemEditDto,
  rate: IRate,
  monthPrice: number
) => {
  const { selectedLocationType, locationRates = [] } = $package;
  let price;
  let priceWithDiscount;
  let maxRate;

  switch (selectedLocationType) {
    default:
      maxRate = 0;
      break;
    case "countries":
      const currentZoneRate = locationRates?.find(
        (rate: IZoneRates) =>
          rate.zoneId === $package.selectedPlan?.zone?.id ||
          rate.zoneId === $package.currentZoneId ||
          0
      ) as IZoneRates;
      const currentZonePlan = currentZoneRate?.plans?.find(
        rate => rate.planId === $package.selectedPlan?.id
      );
      maxRate = { ...currentZonePlan }.rate || 0;
      break;
    case "regions":
      const currentRegionRate = locationRates?.find(
        (rate: IRegionRates) => rate.regionId === $package.locationId
      ) as IRegionRates;
      const currentRegionPlan = currentRegionRate?.plans?.find(
        rate => rate.planId === $package.selectedPlan?.id
      );
      maxRate = { ...currentRegionPlan }.rate || 0;
      break;
    case "subregions":
      const currentSubregionRate = locationRates?.find(
        (rate: ISubregionRates) => rate.subregionId === $package.locationId
      ) as ISubregionRates;
      const currentSubregionPlan = currentSubregionRate?.plans?.find(
        rate => rate.planId === $package.selectedPlan?.id
      );
      maxRate = { ...currentSubregionPlan }.rate || 0;
      break;
  }

  if ($package.traffic?.amount) {
    price = maxRate * $package.traffic.amount + rate.amount;
    price += (price / 100) * rate.fee;

    if ($package.packageCommission && $package.packageCommission !== 0) {
      price = priceCeil(price);
      price += (price / 100) * $package.packageCommission;
    }
    // if ($package.validity?.period === 30) {
    //   if ($package.packageCommission && $package.packageCommission !== 0) {
    //     price = priceCeil(price);
    //     price += (price / 100) * $package.packageCommission;
    //   }
    // }
    // if ($package.validity?.period === 365) {
    //   price = monthPrice;
    //   if ($package.packageCommission && $package.packageCommission !== 0) {
    //     price += (price / 100) * $package.packageCommission;
    //   }
    // }

    // calculate price with discount
    priceWithDiscount = price;
    if ($package.discount) {
      priceWithDiscount -= (priceWithDiscount / 100) * $package.discountAmount;
    }

    return {
      price: priceCeil(price || 0),
      priceWithDiscount: priceCeil(priceWithDiscount || 0)
    };
  } else {
    return {
      price: 0,
      priceWithDiscount: 0
    };
  }
};
