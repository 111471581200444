import {
  ILocationPackageByValidityCategory,
  ILocationPackageListItemPackage
} from "@/hooks/esim/location-packages/types/useLocationPackageListType";
import { sortController } from "@/utills/sort";
import { sortOrders } from "@/enums/main/sortOrders";
import { getDaysByDateTitle } from "@/utills/getDaysByDateTitle";
const sortByAsc = sortController(sortOrders.asc);
const initialPackagesSortedByValidity: ILocationPackageByValidityCategory = {};

export interface IUpdatedPackagesByValidity {
  validityName: string;
  $packages: ILocationPackageListItemPackage[];
  packagesTitles: string[];
}

export type IUpdatedPackagesByPurchaseTypeAndValidity = [
  string,
  IUpdatedPackagesByValidity[]
];

type TSortedByPurchaseType = {
  [key: string]: ILocationPackageListItemPackage[];
};

const initialSortedByPurchaseType: TSortedByPurchaseType = {};

export function getUpdatedPackages(
  packages: ILocationPackageListItemPackage[]
): IUpdatedPackagesByPurchaseTypeAndValidity[] {
  return Object.entries(
    packages.reduce(
      (
        acc: TSortedByPurchaseType,
        $package: ILocationPackageListItemPackage
      ) => {
        const type: string = $package.purchaseType;

        if (acc[type]) {
          acc[type].push($package);
        } else {
          acc[type] = [$package];
        }

        return acc;
      },
      { ...initialSortedByPurchaseType }
    )
  ).map(([purchaseType, $packages]) => {
    return [
      purchaseType,
      Object.entries(
        $packages.reduce(
          (acc, $package) => {
            const title: string = $package?.validity?.title || "";

            if (acc[title]) {
              acc[title].push($package);
            } else {
              acc[title] = [$package];
            }

            return acc;
          },
          { ...initialPackagesSortedByValidity }
        )
      )
        .map(([validityName, $packages]) => {
          $packages.sort((a, b) => {
            return sortByAsc(parseInt(a.title), parseInt(b.title));
          });

          return {
            validityName,
            $packages,
            packagesTitles: $packages.map(({ title }) => title.toLowerCase())
          };
        })
        .sort((a, b) => {
          return sortByAsc(
            getDaysByDateTitle(a.validityName),
            getDaysByDateTitle(b.validityName)
          );
        })
    ];
  });
}
