import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectInput = _resolveComponent("SelectInput")!

  return (_openBlock(), _createBlock(_component_SelectInput, {
    id: "select-purchase-type",
    options: _ctx.optionsList,
    "list-max-height": "250px",
    label: "Purchase Type",
    onSelect: _ctx.selectOption
  }, null, 8, ["options", "onSelect"]))
}