import gsap from "gsap";

export const useListHeightTransition = (baseDelay = 0.15) => {
  const makeDelay = (idx: number) => {
    return baseDelay * idx;
  };

  return {
    beforeEnter(el: HTMLElement) {
      gsap.set(el, { height: 0, autoAlpha: 0 });
    },

    enter(el: HTMLElement, done: () => void | null) {
      const idx: number = parseInt(el.dataset.index || "") || 0;
      const delay = makeDelay(idx);
      gsap.to(el, {
        autoAlpha: 1,
        height: "auto",
        duration: 0.4,
        delay,
        ease: "expo.outIn",

        onComplete: done
      });
    },

    leave(el: HTMLElement, done: () => void | null) {
      const idx: number = parseInt(el.dataset.index || "") || 0;
      const delay = makeDelay(idx);

      gsap.to(el, {
        autoAlpha: 0,
        height: 0,
        delay,
        duration: 0.3,
        ease: "expo.outIn",

        onComplete: done
      });
    }
  };
};
