import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center text-center" }
const _hoisted_2 = { class: "w-100 mt-4" }
const _hoisted_3 = { class: "w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, _mergeProps({
    id: "remove-modal-confirm",
    title: "null",
    "use-header": false
  }, _ctx.$attrs, {
    size: "sm",
    class: "delete-confirm-dialog"
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default"),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_AppButton, {
            size: "xl",
            type: "danger",
            loading: _ctx.loading,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirm')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Yes, Delete ")
            ]),
            _: 1
          }, 8, ["loading"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_AppButton, {
            size: "xl",
            type: "default-transparent",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Cancel ")
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 3
  }, 16))
}